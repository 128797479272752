import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ContentServices";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockTwo from "../components/block/BlockOne";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockFour";
import ValuesOne from "../components/values/ValuesOne";
import BlockThreeAbout from "../components/block/BlockThree";
import GalleryComponent from "../components/Gallery/Gallery";
import { ButtonContent } from "../components/global/boton/ButtonContent";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="w-full mx-auto flex md:flex-row flex-col justify-center items-center">
        <img src={rpdata?.dbPrincipal?.logo} alt="logo" className="md:w-[25%] w-[80%]"/>
        <div className="md:mx-6"><ButtonContent btnName={`Call Us Now!`} /></div>
      </div>
      <HeroSection />
      <div className="md:max-w-full w-full">
        <Modal />
        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.stock?.[1]}
          image2={rpdata?.stock?.[2]}
          image3={rpdata?.stock?.[3]}
          sloganPrincipal={true}
          listServices={true}
        />
        <Directories />
        <CounterUp image={rpdata?.stock?.[5]} />
        <BlockTwo
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.stock?.[4]}
        />
        <BlockThree
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[6]}
          listsAbout={true}
        />
        <ValuesOne image={rpdata?.stock?.[7]} />
        <BlockThreeAbout
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.stock?.[5]}
        />
        <ServicesHome />
        <GalleryComponent/>
        {/* Paleta de Colores */}
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[0]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
